<template>
  <div class="zy-navigation-tab" @click="clickItem">
    <img :src="img_src" :class="[direction != 'left' ? 'zy-navigation-tab-right' : '']" />
    <div :class="['zy-navigation-tab-text', select ? 'zy-navigation-tab-select' : '']">{{ title }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: null,
    }
  },
  props: {
    title: String,
    direction: {
      type: String,
      default: "left",
    },
    // 是否选中
    select: Boolean,
  },
  computed: {
    img_src() {
      if (!this.select) return require("./img/nav_button_normal.png")
      return require("./img/nav_button_select.png")
    },
  },
  methods: {
    clickItem() {
      this.$emit('click')
    }
  },
}
</script>

<style lang="less" scoped>
.zy-navigation-tab {
  width: 128px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &>img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
  }

  &-right {
    transform: rotateY(180deg);
  }

  &-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 16px;
    color: #b1b1b1;
    line-height: 24px;
    text-wrap: nowrap;
    user-select: none;
  }

  &-select {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    text-shadow: 0px 2px 20px rgba(0, 220, 255, 0.91), 0px 2px 6px rgba(255, 255, 255, 0.64);
  }
}
</style>
