<template>
  <div class="zy-navigation-more" @click="changeStatus">
    <img :src="img_src" class="zy-navigation-more-right" />
    <div :class="['zy-navigation-more-text', select ? 'zy-navigation-more-select' : '']">
      <span>{{ children.label || tab.label }}</span>
      <i :class="[show ? 'el-icon-caret-top' : 'el-icon-caret-bottom']" style="color: #38D4DD;text-shadow: unset"></i>
    </div>
    <el-collapse-transition>
      <div class="zy-navigation-more-list" v-show="show">
        <div v-for="item in tab.children"
          :class="['zy-navigation-more-list-item', children.value == item.value ? 'zy-navigation-more-list-select' : '']"
          :key="item.value" @click.stop="toPage(item)">
          {{ item.label }}
        </div>
      </div>
    </el-collapse-transition>

  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  props: {
    tab: {
      type: Object,
      default: () => {
        return {
          children: []
        }
      }
    }
  },
  computed: {
    //列表中的选中项
    children() {
      const path = this.$route.path.replace("/ins", "")
      let children = {}
      this.tab.children.forEach(item => {
        if (item.value == path) {
          children = item
        }
      });
      return children
    },
    //是否选中
    select() {
      return this.children && this.children.value
    },
    img_src() {
      if (!this.select) return require("./img/nav_button_normal.png")
      return require("./img/nav_button_select.png")
    },
  },
  methods: {
    changeStatus(event) {
      console.log(event);
      this.show = !this.show

    },
    toPage(val) {
      this.$router.push({
        path: val.value,
        query: this.$route.query,
      })
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.zy-navigation-more {
  width: 128px;
  height: 50px;
  position: relative;
  cursor: pointer;

  &>img {
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
  }

  &-right {
    transform: rotateY(180deg);
  }

  &-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 16px;
    color: #b1b1b1;
    line-height: 24px;
    text-wrap: nowrap;
    user-select: none;

    &>i {
      margin-left: 6px;
    }
  }

  &-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 55px;
    z-index: 9999;
    background: #1E5087;
    max-height: 500px;
    padding: 6px 0;
    box-shadow: 0px 2px 10px 0px rgba(0, 28, 87, 0.8);
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(24, 123, 205, 1), rgba(51, 216, 250, 1)) 1 1;

    &-item {
      padding: 13px 0;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
    }

    &-item+&-item {
      border-top: 1px solid #2764A6;
    }

    &-select {
      padding: 13px 0;
      font-size: 14px;
      background: #143A64;
      font-weight: bold;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
    }
  }

  &-select {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;

    &>span {
      text-shadow: 0px 2px 20px rgba(0, 220, 255, 0.91), 0px 2px 6px rgba(255, 255, 255, 0.64);
    }
  }
}
</style>
