export default {
  //大屏顶部按钮
  tabs: [
    {
      label: "劳务实名制",
      value: "/jxyh/labor",
    },
    {
      label: "BIM协同管理",
      value: "/jxyh/bim",
    },
    {
      label: "材料管理",
      value: "/jxyh/material",
    },
    {
      label: "进度管理",
      value: "/jxyh/progressManagement",
      hide: (projectId) => {
        return projectId == "39941432740131840"
      },
    },
    {
      label: "安全管理",
      value: "/jxyh/safetyManagement",
    },
    {
      label: "质量管理",
      value: "/jxyh/quality",
    },
    {
      label: "双碳管理",
      value: "/jxyh/bicarbon",
      hide: (projectId) => {
        return projectId == "39941432740131840"
      },
    },
    {
      label: "视频监控",
      value: "/jxyh/monitor",
      // hide: (projectId) => {
      //   //只在总部项目展示
      //   return projectId != "39941432740131840"
      // },
    },
    {
      label: "环境监测",
      value: "/jxyh/robot",
    },
  ],
}
