<template>
  <div :class="['zy-page-header', '/jxyh' == currentPath ? 'zy-page-header-hl' : '']">
    <div class="zy-page-header-day">{{ day_text }}</div>
    <div class="zy-page-header-tabs">
      <zy-navigation class="zy-page-header-tabs-items" :list="tabsLeft" :select="currentPath"
        @change="clickTab"></zy-navigation>
      <div :class="['zy-page-header-tabs-project', '/jxyh' == currentPath ? 'zy-page-header-tabs-project-hl' : '']"
        @click="toPage('/jxyh')">
        {{ screenName }}
      </div>
      <zy-navigation class="zy-page-header-tabs-items" :list="tabsRight" direction="right" :select="currentPath"
        @change="clickTab"></zy-navigation>
    </div>
    <div class="zy-page-header-full" @click="handleFullscreen">
      <img src="../../assets/icons/icon_full.png" />
      {{ is_full_screen ? "退出全屏" : "全屏预览" }}
    </div>
  </div>
</template>

<script>
import ZyNavigation from "@/view/jxyh/components/zy-navigation/index.vue"
import { mapGetters } from "vuex"
import config from "@/jxyh/config.js"
export default {
  components: { ZyNavigation },
  data() {
    return {
      is_full_screen: false,
      day_text: "",
      select: "/jxyh",
      tabsLeft: [],
      tabsRight: [],
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.select = val.path
      },
    },
  },
  computed: {
    currentPath() {
      let path = this.select
      //发版环境多了ins
      path = path.replace("/ins", "")
      return path
    },
    ...mapGetters(["screenName"]),
  },
  created() {
    this.getDayText()
    this.initTabs()
    //监听esc
    document.addEventListener("fullscreenchange", (val) => {
      this.is_full_screen = document.fullscreenElement
    })
  },
  methods: {
    initTabs() {
      let list = []
      const projectId = this.$route.query.projectId
      config.tabs.forEach((tab) => {
        let show = true
        //配置了隐藏项
        if (tab.hide && tab.hide(projectId)) {
          show = false
        }
        if (show) {
          list.push(tab)
        }
      })
      //存在超过8个的情况
      if (list.length > 8) {
        const children = list.splice(7, list.length - 1)
        const more = {
          label: '更多',
          children: children
        }
        list = [...list.splice(0, 7), more]
      }
      let midPoint = Math.ceil(list.length / 2)
      this.tabsLeft = list.slice(0, midPoint)
      this.tabsRight = list.slice(midPoint)
    },
    handleFullscreen() {
      let main = document.body
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (main.requestFullscreen) {
          main.requestFullscreen()
        } else if (main.mozRequestFullScreen) {
          main.mozRequestFullScreen()
        } else if (main.webkitRequestFullScreen) {
          main.webkitRequestFullScreen()
        } else if (main.msRequestFullscreen) {
          main.msRequestFullscreen()
        }
      }
    },
    clickTab(tab) {
      const path = tab.value
      this.toPage(path)
    },
    toPage(path) {
      this.$router.push({
        path,
        query: this.$route.query,
      })
    },
    getDayText() {
      const time = new Date()
      const year = time.getFullYear()
      const month = time.getMonth() + 1
      const day = time.getDate()
      let weak = time.getDay().toString()
      switch (weak) {
        case "1":
          weak = "一"
          break
        case "2":
          weak = "二"
          break
        case "3":
          weak = "三"
          break
        case "4":
          weak = "四"
          break
        case "5":
          weak = "五"
          break
        case "6":
          weak = "六"
          break
        default:
          weak = "日"
      }
      this.day_text = `${year}年${month}月${day}日 星期${weak}`
    },
  },
}
</script>

<style lang="less" scoped>
.zy-page-header {
  background: url("../../assets/images/img_header_bg_nl.png");
  background-size: 100% auto;
  aspect-ratio: 3840/245;
  background-repeat: no-repeat;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-hl {
    background: url("../../assets/images/img_header_bg_hl.png");
    background-size: 100% auto;
    aspect-ratio: 3840/245;
    background-repeat: no-repeat;
  }

  &-day {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    flex-shrink: 0;
    padding-top: 20px;
    width: 180px;
    height: fit-content;
  }

  &-tabs {
    width: 100%;
    height: fit-content;
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-items {
      width: 100%;
      flex: 1;
    }

    &-project {
      font-weight: bold;
      font-size: 36px;
      color: #ffffff;
      line-height: 54px;
      width: 450px;
      padding: 0 20px;
      user-select: none;
      cursor: pointer;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-hl {
        text-shadow: 0px 2px 20px #010d1d, 0px 2px 6px rgba(255, 255, 255, 0.64), 0px 2px 20px rgba(0, 220, 255, 0.9);
      }
    }
  }

  &-full {
    padding-top: 20px;
    width: 180px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    height: fit-content;
    cursor: pointer;

    &>img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
</style>
