<template>
  <div class="zy-navigation" :style="{ 'justify-content': direction != 'left' ? 'flex-start' : 'flex-end' }">
    <template v-for="(tab, index) in list">
      <tab :direction="direction" :key="index" :title="tab.label" @click="clickTab(tab)" :select="tab.value == select"
        v-if="!tab.children"></tab>
      <more v-else :tab="tab" :key="'more' + index"></more>
    </template>
  </div>
</template>

<script>
import Tab from "./tab.vue"
import More from "./more.vue"
export default {
  components: { Tab, More },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: "left",
    },
    // 当前选中的value
    select: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickTab(tab) {
      console.log(tab);
      this.$emit("update:select", tab.value)
      this.$emit("change", tab)
    },
  },
}
</script>

<style lang="less" scoped>
.zy-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;

  &>div {
    font-size: 0;
  }

  &-bg {
    width: 128px;
    height: 50px;
    font-size: 18px;
    color: #b1b1b1;
    line-height: 27px;
    text-align: center;
    background-image: url("./img//nav_button_normal.png");
    background-size: cover;
    /* 或者其他你需要的大小设置 */
    background-position: center;
    /* 根据需要调整位置 */
  }

  &-right {
    // transform: scaleX(-1); /* 垂直翻转 */
  }
}
</style>
